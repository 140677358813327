import { useWhalesyncUser } from '@/hooks/useWhalesyncUser';
import { closeIntercom, initIntercom } from '@/lib/client/intercom';
import { RouteUrls } from '@/utils/route-urls';
import { useAuth, useUser } from '@clerk/nextjs';
import { usePathname } from 'next/navigation';
import defaultPosthog from 'posthog-js';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { attachTokenToAxiosHeader } from '../../lib/client/v1/methods';

import { User } from '@/lib/client/v1/entities';
import { FullPageLoader } from '../v2/common/Loader/FullPageLoader';

const JWT_TOKEN_REFRESH_MS = 10000; // 10 seconds

export const WhaleSyncUserProvider = ({ children }: { children: ReactNode }): JSX.Element => {
  const { isLoading, user, clerkUser, isImpersonating } = useWhalesyncUser();

  useEffect(() => {
    /**
     * Update logging identifiers for the signed-in user.
     */
    if (clerkUser?.id && user) {
      // Dont initialize intercom if user is not present
      // or in impersonation mode
      if (user && !isImpersonating) {
        initIntercom(user);
        if (user.whalesyncUser) trackUserSignIn(user.whalesyncUser);
      }
    } else {
      closeIntercom();
    }

    // Force shutdown in case of impersonation
    if (isImpersonating) {
      closeIntercom();
    }
  }, [clerkUser, isImpersonating, user]);

  if (isLoading) {
    return <FullPageLoader />;
  }

  return <>{children}</>;
};

export const ClerkAuthContextProvider = (props: { children: ReactNode }): JSX.Element => {
  const { getToken } = useAuth();
  const { isLoaded, isSignedIn } = useUser();
  const pathname = usePathname();

  const [tokenLoaded, setToken] = useState(false);

  const loadToken = useCallback(async () => {
    /*
     * Fetch a new JWT token from Clerk. This has to be done using an async function
     */
    const newToken = await getToken({ template: 'whalesync' });

    if (newToken) {
      attachTokenToAxiosHeader(newToken);
      setToken(true);
    }
  }, [getToken]);

  useEffect(() => {
    if (isLoaded && isSignedIn) {
      // load the token any time our auth state changes
      loadToken().catch(console.error);
    }
  }, [isLoaded, isSignedIn, loadToken]);

  /*
   * Periodically refresh the JWT token so the version in authState is as recent as possible
   * TODO: This is a temporary solution to keep the token fresh - ideally this is handled by clerk but we need to
   * change how we are providing the token to all the pages. Instead of storing the token in state, the pages should
   * use the Clerk API to get get the token.
   */
  useEffect(() => {
    const interval = setInterval(() => {
      loadToken().catch(console.error);
    }, JWT_TOKEN_REFRESH_MS);

    return () => clearInterval(interval);
  }, [loadToken]);

  if (RouteUrls.isPublicRoute(pathname)) {
    // Public pages just pass through and can get rendered w/o auth state initialized
    // NOTE: these pages should not attempt to access any user or auth data
    return <>{props.children}</>;
  }

  if (!tokenLoaded) {
    /*
     * Session not authorized and/or user is not yet identified, show a loading screen while we wait for that workflow
     *  to complete
     */
    return <FullPageLoader />;
  }

  /*
   * Session exist, we have a valid JWT, clerk user is loaded AND whalesync user identified
   * AUTH is complete -- any dependant pages are safe to render and utilize user data
   */
  return <WhaleSyncUserProvider>{props.children}</WhaleSyncUserProvider>;
};

/**
 * Posthog tracking to identify user on sign-in
 */
export function trackUserSignIn(user: User): void {
  if (user && user.id) {
    // Wrapped in a try-catch to prevent analytics failures from breaking login flow
    try {
      defaultPosthog.identify(user.id, { email: user.mostRecentEmail });
      defaultPosthog.capture('account_user_sign_in', {
        distinct_id: user.id,
        email: user.mostRecentEmail,
      });
    } catch (error) {
      console.log('Error tracking user sign in', error);
    }
  }
}
