import axios, { AxiosError, AxiosPromise, AxiosRequestConfig } from 'axios';
import _ from 'lodash';
import { Flavor, getBuildFlavor } from '../../../utils/flags';
import {
  AuthApiRequestArgs,
  BaseSyncStatus,
  ClearAndRetrySyncErrorsResponse,
  ConnectorInfo,
  CoreBase,
  CoreBaseDetailLevel,
  CoreBaseSyncState,
  DebugCmrHistoryEntity,
  DebugCoreMetaRecord,
  ExternalBase,
  ExternalConnection,
  ExternalTable,
  FetchBasePreviewsResponse,
  GetUserEmailSuccess,
  HttpException,
  JsonSafeValue,
  ListSyncErrorsResponse,
  ListSyncLogsResponse,
  MappingsEntity,
  NotSupportedResult,
  OAuthAuthorizationData,
  ProductType,
  StringBundle,
  Success,
  SyncError,
  SyncLog,
  SyncPreview,
  SyncPreviewRecordProblem,
  UUID,
  User,
  UserLimitData,
} from './entities';
import { CoreMetaRecordCreate, CoreMetaRecordEntity, CoreMetaRecordPartialUpdate, GridCoreBase } from './entities-grid';
import {
  CloneSyncPreviewDto,
  SyncPreviewDuplicate,
  UpdateSyncPreviewStatusDto,
  UpdateSyncPreviewTablePairDto,
} from './entities/sync-preview';
import {
  CreateFieldsDTO,
  CreateTablesDTO,
  CreateTablesResponse,
  CreatedFieldEntityWithSource,
  Progress,
} from './schema-gen.types';

const backendTargets: Record<Flavor, string> = {
  [Flavor.Local]: process.env.NEXT_PUBLIC_LOCAL_BACKEND ?? `http://localhost:3000`,
  [Flavor.Test]: 'https://test-bottlenose-frontend-service.whalesync.com',
  [Flavor.Staging]: 'https://staging-bottlenose-frontend-service.whalesync.com',
  [Flavor.Production]: 'https://production-bottlenose-frontend-service.whalesync.com',
};

/** All OAuth connectors store the token in the connection bundle under this ID. */
export const OAUTH_CONNECTION_BUNDLE_ITEM_ID = 'apiKey';
export const OAUTH_CONNECTION_BUNDLE_REFRESH_TOKEN_ID = 'refreshToken';
export const OAUTH_CONNECTION_BUNDLE_EXPIRES_IN_ID = 'expiresIn';
export const OAUTH_CONNECTION_BUNDLE_REFRESH_EXPIRES_IN_ID = 'refreshExpiresIn';

const EDIT_CMRS_CLIENT_NAME = 'dusky';

export const apiUrl = backendTargets[getBuildFlavor()];

// ================================================================================
// Helpers
// ================================================================================

/**
 * An error handler function for HTTP calls to provide a way to recover from expected errors. When an HTTP call fails,
 * this error handler will be called to try and handle the error first before giving up and throwing the original error.
 */
type ErrorHandler<T> = (
  e: Error,
  responseJSON: string,
) => Promise<{ result: 'handled'; value: T } | { result: 'unhandled' }>;

export type HttpResponseSuccess<T> = {
  result: 'success';
  response: T;
};

export type HttpResponseException = {
  result: 'exception';
  exception: HttpException;
  axiosError: AxiosError<HttpException>;
};

export type HttpResponseUnknown = {
  result: 'unknown';
  axiosError: AxiosError<unknown>;
};

export type HttpResponse<T> = HttpResponseSuccess<T> | HttpResponseException | HttpResponseUnknown;

export type SkipTakeArgs = { skip: number; take: number };

export const authAxios = axios.create({
  baseURL: apiUrl,
});

export const attachTokenToAxiosHeader = (token: string): void => {
  authAxios.defaults.headers.Authorization = `Bearer ${token}`;
};

/**
 * General method to make HTTP requests. Callers should not wrap this method call in a try/catch block because it will
 * not throw errors except if something is very wrong with Axios.
 */
async function makeRequestV2<T>(config: AxiosRequestConfig): Promise<HttpResponse<T>> {
  try {
    const instance = authAxios;
    const result = await instance.request<T>(config);
    return { result: 'success', response: result.data };
  } catch (error) {
    const err = error as Error;
    if (err.name === 'AxiosError') {
      const axiosError = error as AxiosError<HttpException>;
      if (
        typeof axiosError?.response?.data?.statusCode === 'number' &&
        typeof axiosError?.response?.data?.timestamp === 'string' &&
        typeof axiosError?.response?.data?.path === 'string' &&
        typeof axiosError?.response?.data?.message === 'string' &&
        axiosError?.response?.data?.response?.userFacingMessage !== undefined
      ) {
        // The response has the structure that we expect. It's ready to return it to the caller so it can decide how to
        // handle it.
        return {
          result: 'exception',
          axiosError: axiosError,
          exception: {
            statusCode: axiosError.response.data.statusCode,
            timestamp: axiosError.response.data.timestamp,
            path: axiosError.response.data.path,
            message: axiosError.response.data.message,
            response: axiosError.response.data.response,
          },
        };
      }
      return { result: 'unknown', axiosError: axiosError };
    }

    // It's still good to throw an error here because we expect EVERY error in this catch block to be an AxiosError. If
    // it's not, then something completely unexpected has gone wrong.
    throw error;
  }
}

// TODO(curtis): Delete this function after all methods below are using the new makeRequestV2.
async function makeRequest<ResponseType>(
  config: AxiosRequestConfig,
  errorHandler?: ErrorHandler<ResponseType>,
): Promise<ResponseType> {
  const summary = `${config.method} // ${config.url} ${config.data ? '// ' + JSON.stringify(config.data) : ''}`;
  try {
    const result = await (authAxios(config) as AxiosPromise<ResponseType>).then((res) => res.data);
    console.debug('success:', summary);
    // console.debug(`    Received ${JSON.stringify(result)}`);
    return result;
  } catch (error) {
    const err = error as Error;
    if (err.name === 'AxiosError') {
      const axiosError = error as AxiosError;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const errorMessage =
        (axiosError.response?.data as HttpException)?.response?.userFacingMessage ?? axiosError.message;
      const stringifiedError = JSON.stringify(axiosError);
      const responseJSON = JSON.stringify(_.get(axiosError, 'responseJSON'));
      if (errorHandler) {
        const handledResult = await errorHandler(err, responseJSON);
        if (handledResult.result === 'handled') {
          return handledResult.value;
        }
      }
      console.error(`FAILURE // ${summary} // ${stringifiedError}`);
      console.error(`${errorMessage}`);
      throw error;
    }
    throw error;
  }
}

async function makeAuthRequest<ResponseType>(
  config: AxiosRequestConfig,
  authArgs: AuthApiRequestArgs,
  errorHandler?: ErrorHandler<ResponseType>,
): Promise<ResponseType> {
  config.headers = { ...config.headers };
  return makeRequest(config, errorHandler);
}

/** Gets an error message to show to the user from an HTTP request error. */
export function getMessageFromError(error: unknown): string {
  let message: string | undefined;
  let messageArray: string[] | undefined;
  const responseMessage = (error as AxiosError<HttpException>)?.response?.data?.response?.userFacingMessage;
  if (Array.isArray(responseMessage)) {
    messageArray = responseMessage;
  } else {
    message = responseMessage;
  }
  if (messageArray !== undefined && messageArray.length > 0) {
    return messageArray.join('\n\n');
  } else if (message !== undefined && message) {
    return message;
  } else {
    return 'An error occured. Please contact support.';
  }
}

function convertRawCoreBaseToActualCoreBase(coreBase: CoreBase): CoreBase {
  coreBase.lastSyncTime = new Date(coreBase.lastSyncTime);
  return coreBase;
}

function convertRawCoreBasesToActualCoreBases(coreBases: CoreBase[]): CoreBase[] {
  return coreBases.map((b) => convertRawCoreBaseToActualCoreBase(b));
}

const ApiV1 = {
  // ================================================================================
  // User
  // ================================================================================

  getSignedInUser: async (args: AuthApiRequestArgs): Promise<User> => {
    return await makeAuthRequest({ method: 'get', url: `/rest/users/current` }, args, undefined);
  },

  /**
   * Returns a specified user.
   * This is usually the wrong thing to use; getSignedInUser() will give you your OWN info and should be used instead.
   */
  getUser: async (args: AuthApiRequestArgs & { id: UUID }): Promise<User> => {
    return await makeAuthRequest({ method: 'get', url: `/rest/users/${args.id}` }, args);
  },

  getUserLimitData: async (args: AuthApiRequestArgs): Promise<UserLimitData> => {
    return await makeAuthRequest({ method: 'get', url: `/rest/users/limit-data` }, args);
  },

  searchUsers: async (args: AuthApiRequestArgs & { query: string }): Promise<User[]> => {
    return await makeAuthRequest({ method: 'get', url: `${apiUrl}/rest/users`, params: { q: args.query } }, args);
  },

  deleteUser: async (args: AuthApiRequestArgs & { id: UUID }): Promise<void> => {
    return await makeAuthRequest({ method: 'delete', url: `/rest/users/${args.id}` }, args);
  },

  // ================================================================================
  // Core Base
  // ================================================================================

  createCoreBase: async (args: AuthApiRequestArgs & { name: string }): Promise<CoreBase> => {
    return convertRawCoreBaseToActualCoreBase(
      await makeAuthRequest({ method: 'post', url: `/rest/core-bases`, data: { name: args.name } }, args),
    );
  },

  updateCoreBase: async (
    args: AuthApiRequestArgs & {
      coreBaseId: string;
      syncState?: CoreBaseSyncState;
      name?: string;
      pinned?: boolean;
      mappings?: MappingsEntity;
    },
  ): Promise<CoreBase> => {
    return convertRawCoreBaseToActualCoreBase(
      await makeAuthRequest(
        {
          method: 'patch',
          url: `/rest/core-bases/${args.coreBaseId}`,
          data: { syncState: args.syncState, name: args.name, mappings: args.mappings, pinned: args.pinned },
          params: { detailLevel: CoreBaseDetailLevel.FULL_BASE_SCHEMA },
        },
        args,
      ),
    );
  },

  getCoreBases: async (args: AuthApiRequestArgs): Promise<CoreBase[]> => {
    return convertRawCoreBasesToActualCoreBases(
      await makeAuthRequest(
        {
          method: 'get',
          url: `/rest/core-bases`,
          params: { detailLevel: CoreBaseDetailLevel.FULL_BASE_SCHEMA },
        },
        args,
      ),
    );
  },

  getCoreBase: async (args: AuthApiRequestArgs & { baseId: string; fullDebug?: boolean }): Promise<CoreBase> => {
    return convertRawCoreBaseToActualCoreBase(
      await makeAuthRequest(
        {
          method: 'get',
          url: `/rest/core-bases/${args.baseId}`,
          params: { detailLevel: CoreBaseDetailLevel.FULL_BASE_SCHEMA, fullDebug: args.fullDebug },
        },
        args,
      ),
    );
  },

  deleteCoreBase: async (args: AuthApiRequestArgs & { baseId: string }): Promise<void> => {
    return await makeAuthRequest({ method: 'delete', url: `/rest/core-bases/${args.baseId}?cascade=true` }, args);
  },

  getSyncStatus: async (args: AuthApiRequestArgs & { coreBaseId: string }): Promise<BaseSyncStatus> => {
    return makeAuthRequest<BaseSyncStatus>(
      { method: 'get', url: `/rest/core-bases/${args.coreBaseId}/sync-status` },
      args,
    );
  },

  getGridCoreBase: async (args: AuthApiRequestArgs & { baseId: string }): Promise<GridCoreBase> => {
    return await makeAuthRequest({ method: 'get', url: `/rest/core-bases/grid/${args.baseId}` }, args);
  },

  updateGridCoreBase: async (
    args: AuthApiRequestArgs & {
      coreBaseId: string;
      syncState?: CoreBaseSyncState;
    },
  ): Promise<GridCoreBase> => {
    return makeAuthRequest(
      {
        method: 'patch',
        url: `/rest/core-bases/grid/${args.coreBaseId}`,
        data: { syncState: args.syncState },
      },
      args,
    );
  },

  // ================================================================================
  // Core Meta Records
  // ================================================================================

  getCoreMetaRecords: async (
    args: AuthApiRequestArgs & SkipTakeArgs & { coreBaseId: string; coreTableId: string },
  ): Promise<CoreMetaRecordEntity[]> => {
    return await makeAuthRequest(
      {
        method: 'get',
        url: `/rest/core-bases/${args.coreBaseId}/core-tables/${args.coreTableId}/core-meta-records`,
        params: {
          skip: args.skip,
          take: args.take,
          // TODO(ryder): Filtering.
        },
      },
      args,
    );
  },

  updateCoreMetaRecords: async (
    args: AuthApiRequestArgs & {
      coreBaseId: string;
      coreTableId: string;
      creates: CoreMetaRecordCreate[];
      edits: CoreMetaRecordPartialUpdate[];
    },
  ): Promise<CoreMetaRecordEntity[]> => {
    return makeAuthRequest(
      {
        method: 'patch',
        url: `/rest/core-bases/${args.coreBaseId}/core-tables/${args.coreTableId}/core-meta-records`,
        data: { editClientName: EDIT_CMRS_CLIENT_NAME, creates: args.creates, edits: args.edits },
      },
      args,
    );
  },

  getDebugCoreMetaRecordsByRemoteRecordId: async (
    args: AuthApiRequestArgs & { remoteRecordId: string; externalTableId: UUID },
  ): Promise<DebugCoreMetaRecord[]> => {
    return makeAuthRequest(
      {
        method: 'get',
        url: `/rest/core-meta-records/debug/`,
        params: { remoteRecordId: args.remoteRecordId, externalTableId: args.externalTableId },
      },
      args,
    );
  },

  getDebugCoreMetaRecordsByRemoteRecordSubstring: async (
    args: AuthApiRequestArgs & { remoteRecordSubstring: string; externalTableId: UUID },
  ): Promise<DebugCoreMetaRecord[]> => {
    return makeAuthRequest(
      {
        method: 'get',
        url: `/rest/core-meta-records/debug/`,
        params: { remoteRecordSubstring: args.remoteRecordSubstring, externalTableId: args.externalTableId },
      },
      args,
    );
  },

  getDebugRecordHistory: async (
    args: AuthApiRequestArgs & { coreBaseId: string; query: string; externalTableId?: string },
  ): Promise<DebugCmrHistoryEntity> => {
    return makeAuthRequest(
      {
        method: 'get',
        url: `${apiUrl}/rest/core-meta-records/debug/${args.coreBaseId}/history`,
        params: { query: args.query, externalTableId: args.externalTableId },
      },
      args,
    );
  },

  debugEnqueueEmrPush: async (args: AuthApiRequestArgs & { emrId: string }): Promise<void> => {
    return makeAuthRequest(
      {
        method: 'post',
        url: `${apiUrl}/rest/core-meta-records/debug/enqueue-emr-push`,
        data: { emrId: args.emrId },
      },
      args,
    );
  },

  debugEnqueueEmrGetRecord: async (args: AuthApiRequestArgs & { emrId: string }): Promise<void> => {
    return makeAuthRequest(
      {
        method: 'post',
        url: `${apiUrl}/rest/core-meta-records/debug/enqueue-emr-get-record`,
        data: { emrId: args.emrId },
      },
      args,
    );
  },

  debugMergeCmr: async (
    args: AuthApiRequestArgs & { coreBaseId: string; cmrId: string },
  ): Promise<{ emrId: string; externalBaseId: string; externalTableId: string; connectorType: string }[]> => {
    return makeAuthRequest(
      {
        method: 'post',
        url: `${apiUrl}/rest/core-meta-records/debug/merge-cmr`,
        data: { coreBaseId: args.coreBaseId, cmrId: args.cmrId },
      },
      args,
    );
  },

  debugDeleteAllRecordsForCoreBase: async (args: AuthApiRequestArgs & { coreBaseId: string }): Promise<void> => {
    return makeAuthRequest(
      {
        method: 'delete',
        url: `/rest/core-meta-records/debug/${args.coreBaseId}/all`,
      },
      args,
    );
  },

  // ================================================================================
  // Schema generation
  // ================================================================================

  createTables: async (args: { externalBaseId: string } & CreateTablesDTO): Promise<CreateTablesResponse> => {
    const data: CreateTablesDTO = args;
    const result = await makeAuthRequest<CreateTablesResponse>(
      {
        method: 'post',
        url: `${apiUrl}/rest/generate-schema/${args.externalBaseId}/create-tables`,
        data,
      },
      {},
    );
    return result;
  },

  createFields: async (
    args: {
      destExternalBaseId: string;
    } & CreateFieldsDTO,
  ): Promise<CreatedFieldEntityWithSource[]> => {
    const data: CreateFieldsDTO = args;
    const result = await makeAuthRequest<CreatedFieldEntityWithSource[]>(
      {
        method: 'post',
        url: `${apiUrl}/rest/generate-schema/${args.destExternalBaseId}/create-fields`,
        data,
      },
      {},
    );
    return result;
  },

  // ================================================================================
  // Progress Tracking
  // ================================================================================

  track: async (args: { trackingId: string }): Promise<Progress> => {
    const result = await makeAuthRequest<Progress>(
      {
        method: 'get',
        url: `${apiUrl}/rest/progress/${args.trackingId}`,
      },
      {},
    );
    return result;
  },

  // ================================================================================
  // External Base
  // ================================================================================

  createExternalBase: async (
    args: AuthApiRequestArgs & {
      type: string;
      name: string;
      coreBaseId: string;
      remoteId: string;
      externalConnectionId: string;
      extras?: Record<string, JsonSafeValue>;
    },
  ): Promise<ExternalBase> => {
    const postData: Record<string, unknown> = {
      type: args.type,
      name: args.name,
      remoteId: `${args.remoteId}`,
      coreBaseId: `${args.coreBaseId}`,
      externalConnectionId: `${args.externalConnectionId}`,
    };
    if (args.extras && Object.values(args.extras).length > 0) {
      postData.extras = args.extras;
    }
    const externalBase = await makeAuthRequest<ExternalBase>(
      {
        method: 'post',
        url: `/rest/external-bases`,
        data: postData,
      },
      args,
    );
    return externalBase;
  },

  updateExternalBase: async (
    args: AuthApiRequestArgs & {
      original: {
        type: string;
        name: string;
        remoteId: string;
        coreBaseId: string;
        externalConnectionId: string;
        id: string;
      };
      extras: Record<string, JsonSafeValue>;
    },
  ): Promise<ExternalBase> => {
    const patchData: Record<string, unknown> = {
      type: args.original.type,
      name: args.original.name,
      remoteId: args.original.remoteId,
      coreBaseId: args.original.coreBaseId,
      externalConnectionId: args.original.externalConnectionId,
    };
    if (args.extras) {
      patchData.extras = args.extras;
    }
    const externalBase = await makeAuthRequest<ExternalBase>(
      {
        method: 'patch',
        url: `/rest/external-bases/${args.original.id}`,
        data: patchData,
      },
      args,
    );
    return externalBase;
  },

  updateExternalTable: async (
    args: AuthApiRequestArgs & { id: string; debounceMinMs: number; debounceMaxMs: number },
  ): Promise<ExternalTable> => {
    return makeAuthRequest<ExternalTable>(
      {
        method: 'patch',
        url: `/rest/external-tables/${args.id}`,
        data: { debounceMinMs: args.debounceMinMs, debounceMaxMs: args.debounceMaxMs },
      },
      args,
    );
  },

  getExternalBases: async (
    args: AuthApiRequestArgs & { coreBaseId: string; includeBundle?: boolean },
  ): Promise<ExternalBase[]> => {
    const params: Record<string, string> = {};
    if (args.includeBundle) {
      params['includeBundle'] = 'true';
    }
    const original = await makeAuthRequest<ExternalBase[]>(
      { url: `/rest/external-bases`, params, method: 'get' },
      args,
    );
    const result = original.filter((base) => base.coreBaseId === args.coreBaseId);
    console.debug('Filtered external bases down to: ');
    console.debug(result);
    return result;
  },

  getExternalBase: async (
    args: AuthApiRequestArgs & { externalBaseId: string; includeBundle?: boolean },
  ): Promise<ExternalBase> => {
    const params: Record<string, string> = {};
    if (args.includeBundle) {
      params['includeBundle'] = 'true';
    }
    return makeAuthRequest({ url: `/rest/external-bases/${args.externalBaseId}`, params, method: 'get' }, args);
  },

  /**
   * This method calls the connector for this external base and gets the basic info, most notably name, and updates
   * our record for the external base with the information.
   */
  updateBasePreview: async (args: AuthApiRequestArgs & { externalBaseId: string }): Promise<void> => {
    return makeAuthRequest(
      { url: `/rest/external-bases/${args.externalBaseId}/update-base-preview`, method: 'patch' },
      args,
    );
  },

  /**
   * (From server docs): Force the backend to update the list of {@link ExternalTable}s for an {@link ExternalBase}. It
   * only updates which tables exist and their names. It does not update the table's columns.
   */
  updateTablePreviews: async (args: AuthApiRequestArgs & { externalBaseId: string }): Promise<void> => {
    return makeAuthRequest(
      { url: `/rest/external-bases/${args.externalBaseId}/update-table-previews`, method: 'patch' },
      args,
    );
  },

  // ================================================================================
  // External Table
  // ================================================================================

  /** Ask the server to refresh the schema for the table. */
  updateTableSchema: (args: AuthApiRequestArgs & { externalTableId: string }): Promise<void> => {
    return makeAuthRequest(
      { method: 'patch', url: `/rest/external-tables/${args.externalTableId}/update-schema` },
      args,
    );
  },

  // ================================================================================
  // Connection
  // ================================================================================

  createConnection: (
    args: AuthApiRequestArgs & {
      type: string;
      connectionItems: Record<string, JsonSafeValue>;
      /** If the connector is OAuth, this is the full OAuth data object we got from Bottlenose. */
      // TODO(curtis): Remove this once this is 100% handled server-side.
      oAuthAuthorizationData: OAuthAuthorizationData | null;
    },
  ): Promise<ExternalConnection> => {
    return makeAuthRequest(
      {
        method: 'post',
        url: `/rest/external-connections`,
        data: {
          type: args.type,
          connectionItems: args.connectionItems,
          oAuthAuthorizationData: args.oAuthAuthorizationData,
        },
      },
      args,
    );
  },

  updateConnection: (
    args: AuthApiRequestArgs & {
      externalConnectionId: string;
      connectionItems: Record<string, JsonSafeValue>;
      /** If the connector is OAuth, this is the full OAuth data object we got from Bottlenose. */
      // TODO(curtis): Remove this once this is 100% handled server-side.
      oAuthAuthorizationData: OAuthAuthorizationData | null;
    },
  ): Promise<ExternalConnection> => {
    return makeAuthRequest(
      {
        method: 'patch',
        url: `/rest/external-connections/${args.externalConnectionId}`,
        data: { connectionItems: args.connectionItems, oAuthAuthorizationData: args.oAuthAuthorizationData },
      },
      args,
    );
  },

  getConnections: async (args: AuthApiRequestArgs): Promise<ExternalConnection[]> => {
    return makeAuthRequest({ method: 'get', url: `/rest/external-connections` }, args);
  },

  getConnection: async (
    args: AuthApiRequestArgs & { externalBaseId: UUID },
  ): Promise<{ externalConnection: ExternalConnection; externalBase: ExternalBase } | undefined> => {
    const externalBase = await ApiV1.getExternalBase(args);
    if (externalBase?.externalConnectionId) {
      const connection = await makeAuthRequest<ExternalConnection>(
        {
          method: 'get',
          url: `/rest/external-connections/${externalBase.externalConnectionId}`,
        },
        args,
      );
      return { externalConnection: connection, externalBase };
    }
  },

  deleteConnection: async (args: AuthApiRequestArgs & { externalConnectionId: string }): Promise<void> => {
    return makeAuthRequest({ method: 'delete', url: `/rest/external-connections/${args.externalConnectionId}` }, args);
  },

  getConnectorInfos: async (args: AuthApiRequestArgs): Promise<ConnectorInfo[]> => {
    return makeAuthRequest({ method: 'get', url: `/rest/connector-info/` }, args);
  },

  oAuthAuthorizeCallback: async (args: {
    connectorType: string;
    code: string;
    connectionItems: StringBundle;
    codeVerifier?: string;
  }): Promise<HttpResponse<OAuthAuthorizationData>> => {
    return makeRequestV2({
      method: 'post',
      url: `/rest/external-connections/oauth/${args.connectorType}/authorize-callback`,
      data: { code: args.code, codeVerifier: args.codeVerifier, connectionItems: args.connectionItems },
    });
  },

  getUserEmailFromConnector: async (args: {
    connectorType: string;
    connectionBundleInfo: StringBundle;
    oAuthAuthorizationData: OAuthAuthorizationData;
  }): Promise<HttpResponse<GetUserEmailSuccess | NotSupportedResult>> => {
    return makeRequestV2({
      method: 'get',
      url: `/rest/external-connections/connector-rpc/get-user-email`,
      params: {
        connectorType: args.connectorType,
      },
      data: {
        connectionBundleInfo: args.connectionBundleInfo,
        oAuthAuthorizationData: args.oAuthAuthorizationData,
      },
    });
  },

  fetchBasePreviews: async (
    args: AuthApiRequestArgs & { externalConnectionId: string; baseBundleInfo?: Record<string, JsonSafeValue> },
  ): Promise<FetchBasePreviewsResponse> => {
    return makeAuthRequest(
      {
        method: 'patch',
        url: `/rest/external-connections/${args.externalConnectionId}/fetch-base-previews`,
        data: { baseBundleInfo: args.baseBundleInfo ?? {} },
      },
      args,
    );
  },

  // ================================================================================
  // Temporary External Connection
  // ================================================================================
  createTemporaryExternalConnection: (args: {
    email: string;
    connectorType: string;
    connectionItems: StringBundle;
    /** This is the full OAuth data object we got from Bottlenose. */
    // TODO(curtis): Remove this once this is 100% handled server-side.
    oAuthAuthorizationData: OAuthAuthorizationData;
  }): Promise<Success> => {
    return makeRequest({
      method: 'post',
      url: `/temporary-external-connections/create`,
      data: { email: args.email, connectorType: args.connectorType, connectionItems: args.connectionItems },
    });
  },

  claimTemporaryExternalConnections: (args: AuthApiRequestArgs): Promise<ExternalConnection[]> => {
    return makeAuthRequest(
      {
        method: 'post',
        url: `/temporary-external-connections/claim`,
      },
      args,
    );
  },

  // ================================================================================
  // Sync Logs
  // ================================================================================
  getSyncLogs: (
    args: AuthApiRequestArgs &
      SkipTakeArgs & {
        includeAllTypes: boolean;
        // Filters:
        remoteRecordId?: string;
        coreBaseId?: string;
        externalBaseId?: string;
        externalTableId?: string;
      },
  ): Promise<ListSyncLogsResponse> => {
    return makeAuthRequest(
      {
        url: `/rest/sync-logs`,
        method: 'get',
        params: {
          skip: args.skip,
          take: args.take,
          includeAllTypes: args.includeAllTypes,
          // Filters:
          remoteRecordId: args.remoteRecordId,
          coreBaseId: args.coreBaseId,
          externalBaseId: args.externalBaseId,
          externalTableId: args.externalTableId,
        },
      },
      args,
    );
  },

  getSyncLog: (args: AuthApiRequestArgs & { logId: string }): Promise<SyncLog> => {
    return makeAuthRequest({ url: `/rest/sync-logs/${args.logId}`, method: 'get' }, args);
  },

  // ================================================================================
  // Sync Errors
  // ================================================================================
  getSyncErrors: (
    args: AuthApiRequestArgs &
      SkipTakeArgs & {
        // Filters:
        remoteRecordId?: string;
        coreBaseId?: string;
        externalBaseId?: string;
        externalTableId?: string;
        coreBaseAndConnections?: { coreBaseId: string; externalBaseId1?: string; externalBaseId2?: string };
      },
  ): Promise<ListSyncErrorsResponse> => {
    return makeAuthRequest(
      {
        url: `/rest/sync-errors`,
        method: 'get',
        params: {
          skip: args.skip,
          take: args.take,
          // Filters:
          remoteRecordId: args.remoteRecordId,
          coreBaseId: args.coreBaseId,
          externalBaseId: args.externalBaseId,
          externalTableId: args.externalTableId,
          coreBaseAndConnections: args.coreBaseAndConnections,
        },
      },
      args,
    );
  },

  getSyncError: (args: AuthApiRequestArgs & { errorId: string }): Promise<SyncError> => {
    return makeAuthRequest({ url: `/rest/sync-errors/${args.errorId}`, method: 'get' }, args);
  },

  clearAndRetrySyncErrors: (
    args: AuthApiRequestArgs & { errorId: 'all' | string },
  ): Promise<ClearAndRetrySyncErrorsResponse> => {
    return makeAuthRequest({ url: `/rest/sync-errors/clear-and-retry/${args.errorId}`, method: 'post' }, args);
  },

  // ================================================================================
  // Payment
  // ================================================================================
  generatePaymentCheckoutUrl: (
    args: AuthApiRequestArgs & { productType: ProductType; rewardfulReferral?: string },
  ): Promise<{ url: string }> => {
    return makeAuthRequest(
      {
        url: `/payment/checkout/${args.productType}`,
        method: 'post',
        data: { rewardfulReferral: args.rewardfulReferral },
      },
      args,
    );
  },

  generatePaymentPortalUrl: (args: AuthApiRequestArgs): Promise<{ url: string }> => {
    return makeAuthRequest({ url: `/payment/portal`, method: 'post' }, args);
  },

  syncPreview: {
    async get(args: AuthApiRequestArgs & { id: string }): Promise<SyncPreview> {
      return makeAuthRequest({ url: `/rest/sync-preview/${args.id}`, method: 'get' }, args);
    },

    async listForBase(args: AuthApiRequestArgs & { coreBaseId: string }): Promise<SyncPreview[]> {
      return makeAuthRequest({ url: `/rest/sync-preview/core-bases/${args.coreBaseId}`, method: 'get' }, args);
    },

    async create(args: AuthApiRequestArgs & { coreBaseId: string; coreTableIds: string[] }): Promise<SyncPreview> {
      return makeAuthRequest(
        {
          method: 'post',
          url: `/rest/sync-preview`,
          data: {
            coreBaseId: args.coreBaseId,
            coreTableIds: args.coreTableIds,
          },
        },
        args,
      );
    },

    // TODO(ivan, data-incident): merge with regular create when data incident dust settles
    async createInternal(args: AuthApiRequestArgs & { coreBaseId: string }): Promise<SyncPreview> {
      debugger;
      return makeAuthRequest(
        {
          method: 'post',
          url: `/rest/sync-preview/internal`,
          data: {
            coreBaseId: args.coreBaseId,
          },
        },
        args,
      );
    },

    async clone(args: AuthApiRequestArgs & { syncPreviewId: string } & CloneSyncPreviewDto): Promise<SyncPreview> {
      const data: CloneSyncPreviewDto = { enableRecordMatching: args.enableRecordMatching };
      return makeAuthRequest(
        {
          method: 'post',
          url: `/rest/sync-preview/${args.syncPreviewId}/clone`,
          data,
        },
        args,
      );
    },

    async update(args: AuthApiRequestArgs & UpdateSyncPreviewStatusDto): Promise<SyncPreview> {
      const data: UpdateSyncPreviewStatusDto = {
        id: args.id,
        status: args.status,
        enableRecordMatching: args.enableRecordMatching,
      };
      return makeAuthRequest(
        {
          method: 'patch',
          url: `/rest/sync-preview/${args.id}`,
          data,
        },
        args,
      );
    },

    async updateTablePair(
      args: AuthApiRequestArgs &
        UpdateSyncPreviewTablePairDto & {
          syncPreviewId: string;
          tableMappingId: string;
          enableRecordMatching: boolean;
        },
    ): Promise<SyncPreview> {
      const data: UpdateSyncPreviewTablePairDto = {
        matchOnFieldId: args.matchOnFieldId,
        conflictWinnerSide: args.conflictWinnerSide,
        matchOnSecondaryFieldId: args.matchOnSecondaryFieldId,
        enableRecordMatching: args.enableRecordMatching,
      };
      return makeAuthRequest(
        {
          method: 'patch',
          url: `/rest/sync-preview/${args.syncPreviewId}/table-pair/${args.tableMappingId}`,
          data,
        },
        args,
      );
    },

    async redownloadExternalTable(
      args: AuthApiRequestArgs & {
        syncPreviewId: string;
        tableMappingId: string;
        externalTableId: string;
        enableRecordMatching: boolean;
      },
    ): Promise<void> {
      return makeAuthRequest(
        {
          method: 'patch',
          url: `/rest/sync-preview/${args.syncPreviewId}/table-pair/${args.tableMappingId}/external-table/${args.externalTableId}`,
          data: { enableRecordMatching: args.enableRecordMatching },
        },
        args,
      );
    },

    async getTempTableDuplicates(
      args: AuthApiRequestArgs & { syncPreviewId: string; externalTableId: string },
    ): Promise<SyncPreviewDuplicate[]> {
      return makeAuthRequest(
        {
          method: 'get',
          url: `/rest/sync-preview/${args.syncPreviewId}/temp-table/${args.externalTableId}/duplicates`,
        },
        args,
      );
    },

    async getTempTableProblemRecords(
      args: AuthApiRequestArgs & SkipTakeArgs & { syncPreviewId: string; externalTableId: string },
    ): Promise<SyncPreviewRecordProblem[]> {
      return makeAuthRequest(
        {
          method: 'get',
          url: `${apiUrl}/rest/sync-preview/${args.syncPreviewId}/temp-table/${args.externalTableId}/problems`,
          params: {
            skip: args.skip,
            take: args.take,
          },
        },
        args,
      );
    },

    async debugEnqueueSyncPreviewApplyJob(args: AuthApiRequestArgs & { syncPreviewId: string }): Promise<void> {
      return makeAuthRequest(
        {
          method: 'post',
          url: `${apiUrl}/rest/sync-preview/${args.syncPreviewId}/debug/enqueue-apply-job`,
        },
        args,
      );
    },
  },

  // ================================================================================
  // Dev
  // ================================================================================
  forceApiError: (): Promise<void> => {
    return makeRequest({ url: `/rest/debug/error`, method: 'get' });
  },
};

export default ApiV1;
