import { Badge as MantineBadge } from '@mantine/core';

import classes from './Badge.module.css';

export const Badge = MantineBadge.extend({
  vars: (_, props) => {
    const commonStyles = {
      '--badge-bg': `var(--mantine-color-${props.color}-a-2)`,
      '--badge-color': `var(--mantine-color-${props.color}-10)`,
      '--badge-radius': `var(--mantine-radius-sm)`,
    };

    if (props.variant === 'outline') {
      return {
        root: {
          ...commonStyles,
          '--badge-bd': `1px solid var(--mantine-color-${props.color}-5)`,
        },
      };
    }

    return {
      root: commonStyles,
    };
  },
  classNames: {
    root: classes.root,
  },
  defaultProps: {
    size: 'sm',
    color: 'gray',
    variant: 'outline',
  },
});
